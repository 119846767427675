import { Text } from "@pancakeswap/uikit";
import styled from "styled-components";

export const ContainerMask = styled.div`
    width: 100vw;
    height: 100%;
    position: fixed;
    z-index: 0;
    left: 0;
    bottom: 110px;
    top: 0;
    background: url("/images/mask.png?version=1.2");
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    @media screen and (max-width:576px) {
        bottom: 0px;
        background-position: 50% 60%;
        height: calc(100vh - 90px);
    }
`

export const ContainerComingSoon = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 90vh;
    z-index: 10;
    @media screen and (max-width:576px) {
        align-items: flex-start;
        height: 100%;
        padding-top: 32px;
        min-height: calc(100vh - 220px);
    }
`

export const ContainerContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
`

export const ContainerImage = styled.div`
    width: 100%;
    height: 332px;
    max-width: 1000px;
`

export const ContainerCountDown = styled.div`
    width: 486px;
    height: 120px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding-top: 10px;
    background: #FFFFFF0A;
    box-shadow: 0px 0px 1.5px 0px #FFFFFF4D inset;

    @media screen and (max-width: 768px) {
        width: 405px;
    }
    @media screen and (max-width: 576px) {
        width: 100%;
    }
`

export const CsTextCountDown = styled(Text)`
    font-size: 52px;
    color: white;
    line-height: 57px;
    font-weight: bold;
`

