import { ChainId } from "@pancakeswap/sdk"
import ComingSoonScreen from "views/00_Coming_Soon/coming_soon_screen"

const IndexPage = () => {
    return (
        <ComingSoonScreen />
    )
}
IndexPage.chains = []

export default IndexPage
