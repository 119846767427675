import { useTranslation } from "@pancakeswap/localization";
import React from 'react';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
// import { useLocation } from 'react-router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta';
import { useRouter } from 'next/router';
import { MENU_HEIGHT } from "@pancakeswap/uikit/src/widgets/Menu/config";

const PageMeta = () => {
    const {
        t,
        currentLanguage: { locale },
    } = useTranslation()
    const { pathname } = useRouter()
    const pageMeta = getCustomMeta(pathname, t, locale) || {}
    const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
    const pageTitle = title

    return (
        <MetaTags>
            <title>{pageTitle}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={image} />
        </MetaTags>
    )
}

const PageFullWidth: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
    return (
        <Wrapper>
            <PageMeta />
            {children}
        </Wrapper>
    )
}

export default PageFullWidth

const Wrapper = styled.div`
    max-width: 1320px;
    margin: auto;
    padding: 0px 0px 20px 0px;
    min-height: ${() => `calc(100vh - ${MENU_HEIGHT}px)`};
    @media screen and (max-width: 576px) {
        min-height: ${() => `calc(100vh - ${MENU_HEIGHT + 110}px)`};
    }
    @media screen and (max-width: 1200px) {
        padding: 0px 16px;
    }
`