//node_modules
import Countdown, { zeroPad } from 'react-countdown';
import { Image, Grid } from 'antd';
//components
import PageFullWidth from "components/Layout/PageFullWidth";
//actions
//selector
//function
//constants
//styled
import { ContainerComingSoon, ContainerContent, ContainerCountDown, ContainerImage, ContainerMask, CsTextCountDown } from "./styled";
import { Flex, Text } from '@pancakeswap/uikit';

const { useBreakpoint } = Grid

const ComingSoonScreen = () => {
    // -------------------------- VAR ---------------------------
    const screes = useBreakpoint()
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return null
        }
        return (
            <ContainerCountDown>
                <Flex height="66px" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
                    <Text color='white' style={{textTransform:"uppercase"}}>Days</Text>
                    <CsTextCountDown>{zeroPad(days)}</CsTextCountDown>
                </Flex>
                <Flex height="66px" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
                    <Text color='white' style={{textTransform:"uppercase"}}>HOURS</Text>
                    <CsTextCountDown>{zeroPad(hours)}</CsTextCountDown>
                </Flex>
                <Flex height="66px" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
                    <Text color='white' style={{textTransform:"uppercase"}}>MIN</Text>
                    <CsTextCountDown>{zeroPad(minutes)}</CsTextCountDown>
                </Flex>
                <Flex height="66px" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
                    <Text color='white' style={{textTransform:"uppercase"}}>SEC</Text>
                    <CsTextCountDown>{zeroPad(seconds)}</CsTextCountDown>
                </Flex>
            </ContainerCountDown>
        )
    }
    // -------------------------- MAIN --------------------------
    return (
       <PageFullWidth>
            <ContainerComingSoon>
                <ContainerContent>
                    <Countdown
                        zeroPadTime={2}
                        date={1715327264000}
                        renderer={renderCountdown}
                    />
                    <ContainerImage>
                        <Image 
                            src={screes?.xs ? "/images/coming_soon_mobile.png?version=1.1" : "/images/coming_soon.png?version=1.1"}
                            preview={false}
                            alt="coming-soon"
                            style={{width:"100%"}}
                        />
                    </ContainerImage>
                </ContainerContent>
            </ContainerComingSoon>
            <ContainerMask />
       </PageFullWidth>
    );
};
export default ComingSoonScreen;